import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "usb_type-c"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/usbtypec.png"}) { ...eyecatchImg },
    usbtypec_s: file(relativePath: { eq: "usbtypec/usbtypec.PNG"}) { ...smallImg },
    usb_type_A_B_C_s: file(relativePath: { eq: "usbtypec/usb_type_A_B_C.PNG"}) { ...smallImg },
    pd_chain: file(relativePath: { eq: "usbtypec/pd_chain.PNG"}) { ...normalImg },
    pc_display: file(relativePath: { eq: "usbtypec/pc_display.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`USB Type-Cとは?USB3.1、PD、Altモードなど周辺技術を解説`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="USB TypeCとは" mdxType="Image" />
    <p>{`USB Type-Cを用いてできることは多い。それぞれ後述するが10GBpsを超える高速データ伝送や、PowerDeliveryを使ったノートパソコンの駆動、急速充電、ドッキングステーションとしての利用、オルタネートモード(Altモード)でのThunderbolt 3やDisplayPortプロトコルのサポートなど様々である。`}</p>
    <p>{`しかし`}<strong parentName="p">{`全てのUSB Type-Cがこれらの機能を使えるわけではなく、パソコン、モニタなどの接続機器とケーブルがそれぞれ対応して初めて各機能が有効`}</strong>{`となる。`}</p>
    <p>{`端子がリバーシブルで使いやすくなった半面、`}<strong parentName="p">{`端子形状だけでは使える機能が分からず理解しにくくなったUSB Type-C`}</strong>{`の機能、特徴を分かりやすく説明し、製品選びで迷わない知識の習得を目指す。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "USB-Type-Cはどんな仕様か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AF%E3%81%A9%E3%82%93%E3%81%AA%E4%BB%95%E6%A7%98%E3%81%8B%EF%BC%9F",
        "aria-label": "USB Type Cはどんな仕様か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cはどんな仕様か？`}</h2>
    <p>{`まずはUSB Type-Cの仕様についてざっくりと抑えておこう。`}</p>
    <h3 {...{
      "id": "USB-Type-Cは形状及びピンの役割を定義した規格",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C%E3%81%AF%E5%BD%A2%E7%8A%B6%E5%8F%8A%E3%81%B3%E3%83%94%E3%83%B3%E3%81%AE%E5%BD%B9%E5%89%B2%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%97%E3%81%9F%E8%A6%8F%E6%A0%BC",
        "aria-label": "USB Type Cは形状及びピンの役割を定義した規格 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cは形状及びピンの役割を定義した規格`}</h3>
    <Image {...props} name="usbtypec_s" alt="USB TYPE-C" mdxType="Image" />
USB Type-Cはコネクタの形状及び、ピンの役割を定義した規格である。ピンとは端子内にある入出力を受け付ける口のことで、USB Type-C端子には合計で24のピンが存在する。このピン同士がケーブルで繋がれることによりデータや電力のやり取りがなされるわけである。
    <p>{`Type-Cの要件として全てのピンをそれぞれ等しい用途で利用することは定められておらず、`}<strong parentName="p">{`使用するピンやその用途はケーブルによってある程度自由に決めることができる`}</strong>{`。`}</p>
    <p>{`この結果USB Type-Cのケーブルによって機能や値段に差が出てくるわけである。`}</p>
    <h3 {...{
      "id": "リバーシブル形状で耐久性が大幅に向上",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AA%E3%83%90%E3%83%BC%E3%82%B7%E3%83%96%E3%83%AB%E5%BD%A2%E7%8A%B6%E3%81%A7%E8%80%90%E4%B9%85%E6%80%A7%E3%81%8C%E5%A4%A7%E5%B9%85%E3%81%AB%E5%90%91%E4%B8%8A",
        "aria-label": "リバーシブル形状で耐久性が大幅に向上 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`リバーシブル形状で耐久性が大幅に向上`}</h3>
    <Image {...props} name="usb_type_A_B_C_s" alt="USB TYPE A、TYPE B、TYPE C" mdxType="Image" />
    <p>{`形状は上下対象となっており、端子を挿し込こもうとする時になかなか挿さらず、よく見たところ反対を向いていたことが判明して端子の向きを変えて挿し直すという誰でも経験する地味な面倒くささから開放してくれる。`}</p>
    <p>{`また、耐久性も従来のTypeA/TypeBと比べて大幅に向上している。TypeA/Bでは1500回程度の端子の抜き差しにおける耐久性(挿抜耐久性)しか担保されておらず、頻繁にケーブルの不良が起こっていた。しかし`}<strong parentName="p">{`Type-Cは10000回以上の挿抜耐久性が認証試験で求められており、非常にタフな端子`}</strong>{`となっている。よって長い間使った場合でも故障率は低い。`}</p>
    <h2 {...{
      "id": "USB-Type-Cの伝送速度は対応USB-VersionとGenerationに依存",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E4%BC%9D%E9%80%81%E9%80%9F%E5%BA%A6%E3%81%AF%E5%AF%BE%E5%BF%9CUSB-Version%E3%81%A8Generation%E3%81%AB%E4%BE%9D%E5%AD%98",
        "aria-label": "USB Type Cの伝送速度は対応USB VersionとGenerationに依存 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cの伝送速度は対応USB VersionとGenerationに依存`}</h2>
    <p>{`次にUSB Type-Cの伝送速度について解説したい。使用するピンやその用途によりケーブルの性質が異なるということを前節で書いたが、Type-Cはケーブルやパソコンにより対応するVersionが異なってくる。`}</p>
    <h3 {...{
      "id": "最低はUSB-20準拠の480Mbps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%80%E4%BD%8E%E3%81%AFUSB-20%E6%BA%96%E6%8B%A0%E3%81%AE480Mbps",
        "aria-label": "最低はUSB 20準拠の480Mbps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最低はUSB 2.0準拠の480Mbps`}</h3>
    <p>{`24本あるピンのうち２本がUSB2.0専用の信号線に使われ、8本がSuperSpeed信号線というUSB3.0以上で定義される信号線で使われる。このうちSuperSpeed信号線がごそっと無いType-Cケーブルが販売されており、このケーブルではUSB2.0準拠の480Mbpsが伝送速度となる。`}</p>
    <p><strong parentName="p">{`Type-CだからUSB2.0よりも速いということは保証されていない`}</strong>{`のである。`}</p>
    <h3 {...{
      "id": "USB30準拠で5Gbps、31で10Gbps、32で20Gbpsまで対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB30%E6%BA%96%E6%8B%A0%E3%81%A75Gbps%E3%80%8131%E3%81%A710Gbps%E3%80%8132%E3%81%A720Gbps%E3%81%BE%E3%81%A7%E5%AF%BE%E5%BF%9C",
        "aria-label": "USB30準拠で5Gbps、31で10Gbps、32で20Gbpsまで対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB3.0準拠で5Gbps、3.1で10Gbps、3.2で20Gbpsまで対応`}</h3>
    <p>{`以下USBの対応Versionが上がるごとにUSB3.0では5Gbps、3.1で10Gbps、3.2では20Gbpsまで対応できる。`}</p>
    <p>{`ただ、USB3.1では２つの転送モードがサポートされており、それぞれUSB3.1 Gen1、USB3.2 Gen2と区別される。USB3.1 Gen1はUSB3.0と同様の5Gbps、USB3.1 Gen2が10Gbpsの転送速度である。`}</p>
    <p>{`ここで注意としては`}<strong parentName="p">{`USB3.1対応でもGen2の転送がサポートされていない場合がある`}</strong>{`ことである。この場合USB3.1対応を謳っていても転送速度は5Gbpsとなる。`}</p>
    <p>{`USB3.2もUSB 3.2 Gen1、Gen2、Gen2x2の３種類の規格が存在し、それぞれ5Gbps/10Gbps/20Gbpsの転送速度となっている。
しかし`}<strong parentName="p">{`同様にすべてのUSB3.2がGen2x2をサポートしているわけではないため注意が必要`}</strong>{`である。`}</p>
    <h3 {...{
      "id": "USB規格、転送モード、速度まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB%E8%A6%8F%E6%A0%BC%E3%80%81%E8%BB%A2%E9%80%81%E3%83%A2%E3%83%BC%E3%83%89%E3%80%81%E9%80%9F%E5%BA%A6%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "USB規格、転送モード、速度まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB規格、転送モード、速度まとめ`}</h3>
    <p>{`以上をまとめると下の表のようになる。見ると分かるのだが、USB3.0とUSB3.1 Gen1とUSB3.2 Gen1はそれぞれ全く同じ規格である。同じ規格に対して呼び名が様々であるので購入時には注意しよう。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>規格名</th>
      <th>転送モード(ブランド名)</th>
      <th>転送速度</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>USB2.0</td>
      <td>High-Speed</td>
      <td>480Mbps</td>
    </tr>
    <tr>
      <td>USB3.0</td>
      <td>SuperSpeed</td>
      <td>5Gbps</td>
    </tr>
    <tr>
      <td>USB3.1 Gen1</td>
      <td>SuperSpeed</td>
      <td>5Gbps</td>
    </tr>
    <tr>
      <td>USB3.1 Gen2</td>
      <td>SuperSpeed+</td>
      <td>10Gbps</td>
    </tr>
    <tr>
      <td>USB3.2 Gen1</td>
      <td>SuperSpeed USB</td>
      <td>5Gbps</td>
    </tr>
    <tr>
      <td>USB3.2 Gen2</td>
      <td>SuperSpeed USB 10Gbps</td>
      <td>10Gbps</td>
    </tr>
    <tr>
      <td>USB3.2 Gen2x2</td>
      <td>SuperSpeed USB 20Gbps</td>
      <td>20Gbps</td>
    </tr>
  </tbody>
    </BasicTable>
    <h2 {...{
      "id": "USB-Type-Cの電力供給能力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E8%83%BD%E5%8A%9B",
        "aria-label": "USB Type Cの電力供給能力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cの電力供給能力`}</h2>
    <p>{`最初にUSBの電力供給の基本を説明する。USBから提供される電力をUSBバスパワーと呼び、電力供給を受けてマウスやキーボードを動作させたり、携帯の充電を行うことができる。`}</p>
    <p><strong parentName="p">{`基本的な電力は2.5W(5V/500mA)でこれはUSB標準電力と呼ばれる`}</strong>{`こともある。USB3.0ではこの電力が4.5W(5V/900mA)まで引き上げられている。`}</p>
    <p>{`ところが急速充電を行うため、これより大きな電力を供給する仕組みをUSBは備えている。Type-CのPowerDeliveryも含めそれぞれ解説する。`}</p>
    <h3 {...{
      "id": "USB-BCBattery-Charge",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-BCBattery-Charge",
        "aria-label": "USB BCBattery Charge permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB BC(Battery Charge)`}</h3>
    <p>{`まずは急速充電規格として基本となるUSB BCである。これは格安なものも含めほぼすべてのUSB充電器に搭載されており、敢えてUSB BC対応を打ち出している製品は無い。`}</p>
    <p>{`この規格では最大7.5W(5V/1.5W)までの電力を供給できる。`}</p>
    <p>{`ところでパソコンのUSBポートからの充電が遅いと感じた人はいないだろうか？これはパソコンのUSBポートはUSB2.0で2.5W(USB3.0で4.5W)までの電力供給能力しかないからである。`}</p>
    <h3 {...{
      "id": "USB-Type-C-Currentで15Wまで出力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C-Current%E3%81%A715W%E3%81%BE%E3%81%A7%E5%87%BA%E5%8A%9B",
        "aria-label": "USB Type C Currentで15Wまで出力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-C Currentで15Wまで出力`}</h3>
    <p>{`ようやくUSB Type-Cの話題となるが、USB Type-CではUSBバスパワーが大幅に強化され最大15Wまでを出力できるようになった。この電力供給を特にUSB Type-C Currentと呼んでいる。`}</p>
    <p>{`具体的には２つのモードが存在し、一つ目は`}<strong parentName="p">{`USB Type-C `}<a parentName="strong" {...{
          "href": "mailto:Current@1.5A",
          "target": "_blank",
          "rel": "nofollow noopener"
        }}>{`Current@1.5A`}</a>{`という7.tW(5V/1.5A)の電力を供給する`}</strong>{`モードで、２つ目が`}<strong parentName="p">{`USB Type-C Current@3Aという15W(5V/3W)の電力を供給するモード`}</strong>{`である。`}</p>
    <p>{`ともあれ、これによりパソコンからの電力供給でUSB BCに対応しているACアダプタを上回ることができ、`}<strong parentName="p">{`パソコンからは充電が遅いと感じることはなくなるはず`}</strong>{`である。`}</p>
    <p>{`注意点として、USB Type-Cに接続されているものの、`}<strong parentName="p">{`途中USB TypeBなどに変換している場合は従来通り(2.5W~4.5W)までの出力しかできない`}</strong>{`点が挙げられる。`}</p>
    <p>{`15Wは2.5Wの6倍の電力量に当たり、かなりおもしろい事ができそうである。例えばUSB扇風機はより風が強い送風ができるだろうし、ペットボトルやマグカップを保温するUSB接続器も今後普及しそうである。`}</p>
    <h3 {...{
      "id": "USB-PowerDeliveryPDによる急速充電",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-PowerDeliveryPD%E3%81%AB%E3%82%88%E3%82%8B%E6%80%A5%E9%80%9F%E5%85%85%E9%9B%BB",
        "aria-label": "USB PowerDeliveryPDによる急速充電 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB PowerDelivery(PD)による急速充電`}</h3>
    <p>{`USB BCより速く充電を行える規格は乱立状態である。たとえばクアルコム社のQuick ChargeはSnapdragonというチップを搭載したスマホに対して充電を行え、最大出力は18Wまで(Quick Charge v3の場合)サポートされる。大抵のAndroid端末はSnapdragonを搭載していることから広く普及が進んでいる。`}</p>
    <p>{`また、HUAWEI(ファーウェイ)はSuperCharge 40Wという10V/4Aまでの出力する規格を独自に制定している。`}</p>
    <p>{`しかし、`}<strong parentName="p">{`USB Type-Cの大容量電力供給規格であるPowerDelivery`}</strong>{`によりこの状況は変わってくる可能性がある。`}</p>
    <p><strong parentName="p">{`PowerDeliveryは最大100W(20V/5A)まで出力することができる規格`}</strong>{`であり、それぞれケーブルと接続機器が対応する必要がある。`}</p>
    <p>{`充電時に100Wを供給できるわけではなく、電子機器側と電力供給側での内部的なやりとりを通じ、どこまで電力供給が可能であるかを決定する。このやりとりにより、`}<strong parentName="p">{`給電能力は電圧(5V/9V/15V/20V)と電流(3A/5A)`}</strong>{`の組み合わせにより決定される。`}</p>
    <p>{`iPhone8や最新のAndroid携帯端末がこのUSB PBをサポートしたことから今後普及が急速に進むと思われる。`}</p>
    <h3 {...{
      "id": "パソコンからUSB-PDで電力供給することは無い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8B%E3%82%89USB-PD%E3%81%A7%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E7%84%A1%E3%81%84",
        "aria-label": "パソコンからUSB PDで電力供給することは無い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンからUSB PDで電力供給することは無い`}</h3>
    <p>{`パソコンのUSB Type-C端子から100Wを出力可能というような機器はいまだ発売されていない。基本的にコンセントから電力が供給されることをUSB PDでは想定されており、パソコンからの電力供給は15WのUSB Type-C Current@3Aが現状最大という状況である。`}</p>
    <h3 {...{
      "id": "電力供給能力まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E8%83%BD%E5%8A%9B%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "電力供給能力まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電力供給能力まとめ`}</h3>
    <p>{`最後に上記の電力供給能力をまとめて表にしてみる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>規格名</th>
      <th>最大供給電力</th>
      <th>備考</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>USB2.0</td>
      <td>2.5W(5V/500mA)</td>
      <td>USB標準電力</td>
    </tr>
    <tr>
      <td>USB3.0</td>
      <td>4.5W(5V/900mA)</td>
      <td></td>
    </tr>
    <tr>
      <td>USB BC(Battery Charge)</td>
      <td>7.5W(5V/1.5A)</td>
      <td>ACアダプタからの給電</td>
    </tr>
    <tr>
      <td>USB Type-C Current</td>
      <td>15W(5V/3A)</td>
      <td>7.5W/15Wの２つのモードがある</td>
    </tr>
    <tr>
      <td>USB PB(PowerDelivery)</td>
      <td>100W(20V/5A)</td>
      <td>ACアダプタから。5,9,15,20Vの電圧と3,5Aの組み合わせ</td>
    </tr>
  </tbody>
    </BasicTable>
    <h2 {...{
      "id": "USB-PDでノートパソコンの駆動が可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-PD%E3%81%A7%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%A7%86%E5%8B%95%E3%81%8C%E5%8F%AF%E8%83%BD",
        "aria-label": "USB PDでノートパソコンの駆動が可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB PDでノートパソコンの駆動が可能`}</h2>
    <p>{`充電について話してきたが、急速充電で使われる電力はせいぜいが20W程度である。これはスマートホンが熱に耐えられない、危険性、安定性の問題など様々な理由があると思われる。`}</p>
    <p>{`では、それ以上の電力を何に使うのかと言われたときに`}<strong parentName="p">{`最も想定されているケースがノートパソコンの駆動及び充電`}</strong>{`である。グラフィックボードを搭載しているゲーム用のノートパソコンの駆動はPDでは難しいが、MacBookなど大抵のノートパソコンは45W程度の電力供給で駆動することができる。`}</p>
    <p>{`今後は鬱陶しいACアダプタを持ち歩く必要はなく、USB Type-Cの電源のみを持ち運べばスマホもパソコンも両方充電ができ、便利になることは間違いない。`}</p>
    <h3 {...{
      "id": "USB-PDの数珠繋ぎが可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-PD%E3%81%AE%E6%95%B0%E7%8F%A0%E7%B9%8B%E3%81%8E%E3%81%8C%E5%8F%AF%E8%83%BD",
        "aria-label": "USB PDの数珠繋ぎが可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB PDの数珠繋ぎが可能`}</h3>
    <p>{`USBドック(USBハブ)という複数のUSB端子を増設する機器を繋ぐときにこの数珠繋ぎの機能は重宝する。電源アダプタからハブへ電力供給を行い、そのハブ経由でUSB Type-Cケーブルを用いてノートパソコンなどへ電力供給ができる。ノートパソコンにマウスやキーボード、プリンタ、USBメモリなど複数の周辺機器を接続したい場合で同時に充電や駆動を行いたい場合には重要な機能となる。`}</p>
    <Image {...props} name="pd_chain" alt="USB PDによる電力供給" mdxType="Image" />
    <h2 {...{
      "id": "USB-Type-Cの映像伝送能力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E6%98%A0%E5%83%8F%E4%BC%9D%E9%80%81%E8%83%BD%E5%8A%9B",
        "aria-label": "USB Type Cの映像伝送能力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cの映像伝送能力`}</h2>
    <p>{`USB Type-Cは映像や音声をモニタへ向けて送信することが可能である。ただし、全てのUSB Type-C機器、ケーブルがこれに対応しているわけではなく、オルタネートモードに対応したものだけが可能となる。`}</p>
    <p>{`オルタネートモード(Alternate Mode、Altモードとも)とは、Type-Cの24個のピンのうち高速データ伝送を行う8つのピンに対して、別のプロトコルで動作させることが可能なモードである。映像伝送を行う時にはこのオルタネートモードを利用する。`}</p>
    <p>{`代表的なモードとしてはThunderbolt 3とDisplayPortがあるのでそれぞれ説明していく。`}</p>
    <h3 {...{
      "id": "Thunderbolt-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Thunderbolt-3",
        "aria-label": "Thunderbolt 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thunderbolt 3`}</h3>
    <p>{`Thunderbolt 3は`}<strong parentName="p">{`USB3.1 Gen2の10Gbpsでデータ転送可能でかつ40Gbpsまでの映像出力を可能にするインターフェース`}</strong>{`である。`}</p>
    <p>{`具体的にはThunderbolt 3規格に準拠している場合は下記が保証される。`}</p>
    <AccentBox title="Thunderbolt 3で可能なこと" mdxType="AccentBox">
  <li><span className="bold">USB 3.1 Gen2</span>・・・10Gbpsでのデータ転送が可能</li>
  <li><span className="bold">DisplayPort 1.2</span>・・・4K@60Hzのディスプレイの出力が可能。</li>
  <li><span className="bold">Thunderbolt 3出力</span>・・・5K@60Hzまでの映像に対応できる。</li>
  <li><span className="bold">USB PD</span>・・・100W(5V/20W)までの電力供給</li>
    </AccentBox>
    <p>{`見ると分かるが、USB Type-Cで可能なことがほぼ全て備わっており、`}<strong parentName="p">{`Thunderbolt 3に対応していさえすれば取り合えずType-Cの機能で書かれていることは何でも可能`}</strong>{`と考えても問題がない。`}</p>
    <p>{`4K画面では60Hzにこだわった方が良い。30Hzまでの対応では画面スクロールやマウスの動きが滑らかではないし、ゲームを行うにしてもカクカクした動きになってしまう。`}</p>
    <p><strong parentName="p">{`合計で4K60Hzで２画面(あるいは5K60Hz1枚)まで対応する`}</strong>{`のが映像伝送能力の凄さといえる。具体的には`}<a parentName="p" {...{
        "href": "https://amzn.to/2jXGfwX",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`2ポートDisplayPort変換アダプタ `}</a>{`のような製品を使い２画面出力を行うか、下記の`}<a parentName="p" {...{
        "href": "https://amzn.to/37Zf5vv",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`belkin Thunderbolt 3ドック`}</a>{`のようなThunderbolt 3対応Dockを使って行うかのいずれかである。`}</p>
    <a href="https://www.amazon.co.jp/%E3%83%89%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%B9%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3-Thunderbolt3-5K%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E5%AF%BE%E5%BF%9CThunderbolt3-0-8m%E3%82%B1%E3%83%BC%E3%83%96%E3%83%AB%E4%BB%98-F4U097JA/dp/B085CSK9JQ?dchild=1&keywords=Thunderbolt+3+%E3%83%89%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%B9%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3&qid=1614607985&sr=8-5&linkCode=li3&tag=daigakuseitus-22&linkId=1eb62d2dac946e08a2cec1dbdc254c88&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow noopener"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B085CSK9JQ&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=daigakuseitus-22&language=ja_JP" /></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=daigakuseitus-22&language=ja_JP&l=li3&o=9&a=B085CSK9JQ" width="1" height="1" border="0" alt="" style={{
      "border": "none !important",
      "margin": "0px !important"
    }} />
    <h3 {...{
      "id": "DisplayPort",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DisplayPort",
        "aria-label": "DisplayPort permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DisplayPort`}</h3>
    <p>{`Thunderbolt 3対応の製品はケーブルも含めて高価である。例として`}<a parentName="p" {...{
        "href": "https://amzn.to/2ltZoax",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Anker USB-C & USB-C Thunderbolt 3 ケーブル`}</a>{`は3000円程度する。より安価にしたい場合DisplayPort Over USB Type-CというDisplayPortのオルタネートモードにのみ対応したケーブルが販売されている。これは半額程度で購入が可能である。`}</p>
    <h2 {...{
      "id": "ノートパソコンからデュアルモニタ環境を瞬時に構築",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8B%E3%82%89%E3%83%87%E3%83%A5%E3%82%A2%E3%83%AB%E3%83%A2%E3%83%8B%E3%82%BF%E7%92%B0%E5%A2%83%E3%82%92%E7%9E%AC%E6%99%82%E3%81%AB%E6%A7%8B%E7%AF%89",
        "aria-label": "ノートパソコンからデュアルモニタ環境を瞬時に構築 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンからデュアルモニタ環境を瞬時に構築`}</h2>
    <p>{`Type-Cの機能を活用すると次の図ように簡単にノートパソコンからデスクトップのデュアルモニタ環境を作り上げることができる。`}</p>
    <Image {...props} name="pc_display" caption="Type-Cを利用したデュアルモニタ環境の構築" mdxType="Image" />
    <p>{`デュアルモニタにすると仕事効率が上がるため、家ではリッチなディスプレイ環境で作業を行いたいという人に適している。家に帰ってディスプレイから生えているケーブルを一本挿せばよいだけなのでシームレスに作業が継続できる。ここには重要な技術が含まれておりそれらについて解説する。`}</p>
    <h3 {...{
      "id": "映像伝送と電力供給がUSB-Type-C一本で可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%98%A0%E5%83%8F%E4%BC%9D%E9%80%81%E3%81%A8%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E3%81%8CUSB-Type-C%E4%B8%80%E6%9C%AC%E3%81%A7%E5%8F%AF%E8%83%BD",
        "aria-label": "映像伝送と電力供給がUSB Type C一本で可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`映像伝送と電力供給がUSB Type-C一本で可能`}</h3>
    <p>{`まず、注目すべき点は映像出力とPD電力供給を同時に実現していることである。１本のType-Cで両方行えているため、わざわざ`}<strong parentName="p">{`ACアダプタを挿してノートパソコンを充電する必要が無い`}</strong>{`。
これは電力供給のソース/シンクと映像出力におけるホスト/デバイスの方向をそれぞれ制御できる`}<strong parentName="p">{`デュアルロール`}</strong>{`という制御機能のおかげで成り立っている。`}</p>
    <h3 {...{
      "id": "デイジーチェーンによるディスプレイの数珠繋ぎ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%A4%E3%82%B8%E3%83%BC%E3%83%81%E3%82%A7%E3%83%BC%E3%83%B3%E3%81%AB%E3%82%88%E3%82%8B%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%81%AE%E6%95%B0%E7%8F%A0%E7%B9%8B%E3%81%8E",
        "aria-label": "デイジーチェーンによるディスプレイの数珠繋ぎ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デイジーチェーンによるディスプレイの数珠繋ぎ`}</h3>
    <p>{`またノートパソコン及びケーブルがDisplayPortのオルタネートモードに対応していてかつ、ディスプレイにデイジーチェーン用のDisplayPortが存在する場合、モニタを数珠繋ぎで連結することができ、簡単にデュアルモニタ環境を構築することができる。`}</p>
    <h3 {...{
      "id": "モニタのUSBハブとしての機能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%81%AEUSB%E3%83%8F%E3%83%96%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E6%A9%9F%E8%83%BD",
        "aria-label": "モニタのUSBハブとしての機能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニタのUSBハブとしての機能`}</h3>
    <p>{`Type-Cでのディスプレイ出力を行えるモニタには大抵USBポートがいくつか備え付けられている。この場合モニタをUSBドック(USBハブ)として使うことができる。`}</p>
    <p>{`プリンタやキーボードなどを優先で繋いでも良いし、無線通信用のアダプタを挿してワイヤレスマウスを使えるようにしても良い。`}</p>
    <p>{`ポイントとしては`}<strong parentName="p">{`モニタがあるだけで、周辺機器を含めて環境を構築してくれるということ`}</strong>{`である。持ち運び用にノート、家ではデスクトップというように使い分けている人はこの方法を試してみるのはありかもしれない。`}</p>
    <h3 {...{
      "id": "USB-Type-C対応モニタは少ない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C%E5%AF%BE%E5%BF%9C%E3%83%A2%E3%83%8B%E3%82%BF%E3%81%AF%E5%B0%91%E3%81%AA%E3%81%84",
        "aria-label": "USB Type C対応モニタは少ない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-C対応モニタは少ない`}</h3>
    <p>{`最後にUSB Type-C対応モニタはそれほど普及はしていない。4Kモニターなら多くが対応しているがまだ値段は高価。FullHD相当ならば、下記の`}<a parentName="p" {...{
        "href": "https://amzn.to/2jYNDYZ",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`DELL P2419HC`}</a>{`がデイジーチェーンにも対応しておりおすすめである。`}</p>
    <a href="https://www.amazon.co.jp/P-series-P2419HC-%E3%83%97%E3%83%AD%E3%83%95%E3%82%A7%E3%83%83%E3%82%B7%E3%83%A7%E3%83%8A%E3%83%AB%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA-23-8%E3%82%A4%E3%83%B3%E3%83%81USB-C%E3%83%A2%E3%83%8B%E3%82%BF/dp/B07GBY2M8V?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&keywords=Dell+P2419HC&qid=1563175568&s=gateway&sr=8-1&linkCode=li3&tag=daigakuseitus-22&linkId=a3a01b9fbae6dbbebf1f8795ca9a0c2d&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow noopener"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07GBY2M8V&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=daigakuseitus-22&language=ja_JP" /></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=daigakuseitus-22&language=ja_JP&l=li3&o=9&a=B07GBY2M8V" width="1" height="1" border="0" alt="" style={{
      "border": "none !important",
      "margin": "0px !important"
    }} />
    <h2 {...{
      "id": "その他Type-Cでおすすめの機能、注意点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96Type-C%E3%81%A7%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E6%A9%9F%E8%83%BD%E3%80%81%E6%B3%A8%E6%84%8F%E7%82%B9",
        "aria-label": "その他Type Cでおすすめの機能、注意点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他Type-Cでおすすめの機能、注意点`}</h2>
    <p>{`最後に他に知っておいた方が良さそうなことをもろもろ紹介する。`}</p>
    <h3 {...{
      "id": "Thunderboltケーブルは長さにより伝送速度が変わる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Thunderbolt%E3%82%B1%E3%83%BC%E3%83%96%E3%83%AB%E3%81%AF%E9%95%B7%E3%81%95%E3%81%AB%E3%82%88%E3%82%8A%E4%BC%9D%E9%80%81%E9%80%9F%E5%BA%A6%E3%81%8C%E5%A4%89%E3%82%8F%E3%82%8B",
        "aria-label": "Thunderboltケーブルは長さにより伝送速度が変わる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thunderboltケーブルは長さにより伝送速度が変わる`}</h3>
    <p>{`Thunderbolt対応ケーブルはその長さにより、伝送能力速度に差が出てしまう。一般的なThunderboltケーブルの場合、1m未満は最大40Gbpsで通信ができるが、`}<strong parentName="p">{`1m以上となると20Gbpsまで最大伝送速度が落ちてしまう`}</strong>{`。これはケーブル長による信号の減衰のためこの仕様になったと思われる。`}</p>
    <p>{`この長さにより伝送速度が変わるタイプのケーブルについて、`}<strong parentName="p">{`Thunderboltケーブルがパッシブタイプ`}</strong>{`であると表現するが、信号補正機能を備え、`}<strong parentName="p">{`伝送速度を保つことが可能なアクティブタイプのThunderboltケーブル`}</strong>{`も販売されている。`}</p>
    <p>{`さらに話がややこしいのだが、このアクティブ・タイプはThunderbolt 3専用で、Thunderboltに対応していない機器のケーブルとしては使用できないので使いまわすときは注意したい。`}</p>
    <h3 {...{
      "id": "外付けのグラフィックボードにThunderboltで対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%96%E4%BB%98%E3%81%91%E3%81%AE%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E3%81%ABThunderbolt%E3%81%A7%E5%AF%BE%E5%BF%9C",
        "aria-label": "外付けのグラフィックボードにThunderboltで対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`外付けのグラフィックボードにThunderboltで対応`}</h3>
    <p>{`ゲーミングのノートパソコンは重量が軽量のものでも2kgはあり、非常に重い。よって持ち歩くにはちょっと不便である。しかしThunderboltの高速伝送を用いた外付けのGPUボックスが発売されている。`}</p>
    <p>{`ノートパソコンを外付けGPUに繋ぎ、GPUが映像をレンダリングし、その結果をノートパソコンの画面、あるいは外部ディスプレイへ出力してくれるのである。`}</p>
    <p>{`ある程度ノートパソコンにCPUのスペックが無いと、CPUがボトルネックとなり処理速度が落ちることはあるのでハイスペックなノートと組み合わせて使うのが良い。`}</p>
    <p>{`ともあれこのボックスを使うことにより、`}<strong parentName="p">{`軽量のノートパソコン一台でゲームも含めて実行`}</strong>{`することができる。`}</p>
    <h3 {...{
      "id": "USB-Type-C-FullFeaturedケーブルのUSBドックは4K30Hzまで",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C-FullFeatured%E3%82%B1%E3%83%BC%E3%83%96%E3%83%AB%E3%81%AEUSB%E3%83%89%E3%83%83%E3%82%AF%E3%81%AF4K30Hz%E3%81%BE%E3%81%A7",
        "aria-label": "USB Type C FullFeaturedケーブルのUSBドックは4K30Hzまで permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-C FullFeaturedケーブルのUSBドックは4K@30Hzまで`}</h3>
    <p>{`USB Type-Cは大きく分けてTunderboltケーブル、FullFeaturedケーブル、USB 2.0 Type-Cの３つに分けられ、FullFeaturedケーブルはPDやDisplayPortのオルタネートモード等一通りの機能を備えているものを言う。`}</p>
    <p>{`様々な機能を一つのケーブルから利用する時に、わざわざUSBを抜き差しするのは面倒くさい。そこでUSBドック(ドッキングステーション、USBハブ)が用いられる。`}</p>
    <p>{`しかし伝送能力に限界があるため、4K@60HzをUSB FullFeaturedで実現しようとすると他のUSB 3.1のデータ伝送機能が使えなくなってしまう。そこで4K@30Hzまでに大抵のUSBドックは抑えられている。`}</p>
    <p>{`4K@60HzをUSBドック経由で行う場合はThunderboltにドックも機器も対応する必要がある。もっともThunderbolt用ドックは数万円かかるためおいそれとはいかないが。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      